<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="neo()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="https://osai-as.com/wp-content/uploads/2016/07/neorouter_modula.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                Neorouter 

                </h4>
                
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="aur()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100% ;margin:auto"
                class="card-img-top img-fluid"
                src="https://www.aurotek.com.tw/uploads/images/1568701502.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 AUROTEK




                </h4>
                
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        
      
      </div>
       
   
     
     
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
       neo(){
        window.location.href = "https://osai-as.com/en/neorouter-modula";
    },
       aur(){
        window.location.href = "https://www.aurotek.com.tw/en/product/product-list/c03/124";
    },
  },
};
</script>